import { CustomModalErrors } from "@components/CustomModalErrors"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import LoginForm from "@modules/Auth/Admin/components/SigninForm"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import { Col, Row } from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import styles from "./style.module.css"

function PageLogin() {
  const [loading, setLoading] = useState(false)
  const [loadingRecover, setLoadingRecover] = useState(false)
  const [visible, setVisible] = useState(false)
  const [errors, setErrors] = useState([])
  const [errorRecover, setErrorRecover] = useState(false)

  const isSmallDevice = useMedia({ maxWidth: 1023 })
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1150 })
  const isDesktopLarge = useMedia({ minWidth: 1151, maxWidth: 1919 })

  const { auth } = useStores()

  const {
    login,
    forgotPassword,
    loginErrorMessage,
    clearLoginErrorMessage,
    user,
  } = auth

  useEffect(() => {
    if (user) {
      navigate("/admin/assinantes")
    }
  }, [user])

  useEffect(() => {
    const adaptedErrors = errorsAdapter(loginErrorMessage)
    setErrors(adaptedErrors)
  }, [loginErrorMessage])

  const handleLogin = async (props) => {
    setLoading(true)
    await login({ email: props.user, password: props.password })
    setLoading(false)
  }

  const handleRecoverMyPassword = async (props) => {
    setLoadingRecover(true)
    const response = await forgotPassword({ email: props.email })

    if (response) {
      setVisible(true)
      setErrorRecover(response)
      setLoadingRecover(false)
      return
    }

    setVisible(false)
    setErrorRecover(response)
    setLoadingRecover(false)
  }

  const handleClose = (props) => {
    setVisible(props)
    setErrorRecover(false)
  }

  if (isSmallDevice) {
    return (
      <Row className="vh-100 justify-center items-center mh16">
        <Col span={24} sm={12} offset={{ span: 0, sm: 6 }}>
          <TranslateWrapper>
            <LoginForm
              loading={loading}
              loadingRecover={loadingRecover}
              visible={visible}
              errorRecover={errorRecover}
              handleLogin={handleLogin}
              handleRecoverMyPassword={handleRecoverMyPassword}
              handleClose={handleClose}
            />
          </TranslateWrapper>
        </Col>

        <CustomModalErrors
          errors={errors}
          setErrors={(errors) => {
            setErrors(errors)
            clearLoginErrorMessage()
          }}
        />
      </Row>
    )
  }

  return (
    <div>
      <img
        className={cx("fr vh-100", styles.imageSize)}
        src={require(`@images/background-admin-${
          isDesktop ? "1024" : isDesktopLarge ? "1366" : "1920"
        }.png`)}
      />
      <div className={cx("bg-white br3 absolute", styles.loginFormContainer)}>
        <TranslateWrapper>
          <LoginForm
            loading={loading}
            loadingRecover={loadingRecover}
            visible={visible}
            errorRecover={errorRecover}
            handleLogin={handleLogin}
            handleRecoverMyPassword={handleRecoverMyPassword}
            handleClose={handleClose}
          />
        </TranslateWrapper>
      </div>

      <CustomModalErrors
        errors={errors}
        setErrors={(errors) => {
          setErrors(errors)
          clearLoginErrorMessage()
        }}
      />
    </div>
  )
}

export default observer(PageLogin)
