import { Icon } from "@components/Icons"
import RecoverPassword from "@components/recoverPassword"
import translate from "@src/i18n"
import { Button, Form, Input } from "antd"
import cx from "classnames"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "../../style.module.less"

const LoginForm = (props) => {
  const intl = useIntl()

  const [completedStyleUser, setCompletedStyleUser] = useState("")
  const [completedStylePassword, setCompletedStylePassword] = useState("")

  const {
    handleClose,
    handleRecoverMyPassword,
    handleLogin,
    errorRecover,
    visible,
    loadingRecover,
    loading,
  } = props

  const validateMessages = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
    types: {
      email: intl.formatMessage({ id: "emailInputMessage" }),
    },
  }

  const handleSubmit = (props) => {
    handleLogin(props)
  }

  const handleBlurUser = (e) => {
    if (e.target.value) {
      setCompletedStyleUser(styles.completedInput)
    }
  }

  const handleBlurPassword = (e) => {
    if (e.target.value) {
      setCompletedStylePassword(styles.completedInput)
    }
  }

  return (
    <div className="flex flex-column w-100 h-100 loginForm">
      <img
        className={cx("center mb12", styles.logo)}
        src={require("@images/logo.png")}
      />

      <p
        className={cx(
          "f14 fw6 mb32 self-center tc",
          styles.descriptionContainer
        )}
      >
        <span className="kimberly">{translate("loginAdmin.first")}</span>

        <span className={cx("normal", styles.labelTwo)}>
          {translate("loginAdmin.second")}
        </span>
      </p>

      <Form
        validateMessages={validateMessages}
        onFinish={handleSubmit}
        initialValues={{
          user: "",
          password: "",
        }}
      >
        <Form.Item name="user" rules={[{ type: "email", required: true }]}>
          <Input
            className={(styles.inputUser, completedStyleUser)}
            placeholder="Usuário"
            disabled={loading}
            onBlur={handleBlurUser}
          />
        </Form.Item>

        <Form.Item
          name="password"
          validateFirst
          validateStatus={"error"}
          rules={[
            {
              type: "string",
              required: true,
              min: 6,
            },
            {
              pattern:
                /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
              message: intl.formatMessage({ id: "passwordError" }),
            },
          ]}
        >
          <Input.Password
            className={completedStylePassword}
            placeholder="Senha"
            iconRender={(visible) =>
              visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
            }
            disabled={loading}
            onBlur={handleBlurPassword}
          />
        </Form.Item>

        <div className={cx("flex justify-center", styles.forgotPasswordMargin)}>
          <a
            className="kimberly underline f14 fw-normal mb24"
            onClick={() => handleClose(true)}
          >
            {translate("forgotMyPassword")}
          </a>
        </div>

        <Form.Item className="submitButton tc">
          <Button type="primary" htmlType="submit" loading={loading}>
            {translate("enter")}
          </Button>
        </Form.Item>
      </Form>

      <RecoverPassword
        visible={visible}
        loadingRecover={loadingRecover}
        errorRecover={errorRecover}
        handleRecoverMyPassword={handleRecoverMyPassword}
        handleClose={handleClose}
      />
    </div>
  )
}

export default LoginForm
