import MyAccountModal from "@components/myAccountModal"
import { Button, Form, Input } from "antd"
import cx from "classnames"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "./style.module.less"

const RecoverPassword = (props) => {
  const intl = useIntl()

  const [completedStyle, setCompletedStyle] = useState("")

  const {
    handleClose,
    handleRecoverMyPassword,
    errorRecover,
    visible,
    loadingRecover,
  } = props

  const validateMessages = {
    required: intl.formatMessage({ id: "requiredInput" }),
    types: {
      email: intl.formatMessage({ id: "emailInputMessage" }),
    },
  }

  const handleBlur = (e) => {
    if (e.target.value) {
      setCompletedStyle(styles.completedInput)
    }
  }

  const handleRecover = (props) => {
    handleRecoverMyPassword(props)
  }

  return (
    <MyAccountModal visible={visible} handleClose={() => handleClose(false)}>
      <div className={cx("recoverPassword", styles.containerDescription)}>
        <div className="tc">
          <span className="f20 fw5 mine-shaft">Recuperar senha</span>
          <p className="pt8">
            <span className={cx("f14 fw5", styles.labelTwo)}>
              Digite seu e-mail logo abaixo,{" "}
            </span>
            <span className="f14 normal kimberly">
              para redefinir sua senha.
            </span>
          </p>
        </div>
        <Form validateMessages={validateMessages} onFinish={handleRecover}>
          <div className={cx("pt24", styles.inputPadding)}>
            <Form.Item name="email" rules={[{ type: "email" }]}>
              <Input
                type="email"
                placeholder="Digite seu e-mail"
                className={completedStyle}
                disabled={loadingRecover}
                onBlur={handleBlur}
              />
            </Form.Item>
          </div>
          {errorRecover && (
            <div className="mb24 tc">
              <span className={cx("f14 b", styles.colorError)}>
                Houve um erro ao tentar recuperar sua senha, por favor, tente
                novamente.
              </span>
            </div>
          )}
          <div className="tc">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loadingRecover}>
                Enviar
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </MyAccountModal>
  )
}

export default RecoverPassword
